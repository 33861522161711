import { RootState } from '@/stores'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { SelectItem } from '../components/CourseTypeSelect'

interface CourseState {
  items: SelectCheckItem[]
}

interface SelectCheckItem extends SelectItem {
  checked: boolean
}

const initialState: CourseState = {
  items: [],
}

const CourseSlice = createSlice({
  name: 'course',
  initialState,
  reducers: {
    setCourseItem(state, action: PayloadAction<SelectCheckItem>) {
      if (
        state.items.findIndex((item) => item.label === action.payload.label) !==
        -1
      ) {
        state.items = state.items.filter(
          (item) => item.label !== action.payload.label
        )
      }
      state.items.push(action.payload)
    },
  },
})

export const { setCourseItem } = CourseSlice.actions

export const selectCheckItems = (state: RootState): SelectCheckItem[] =>
  state.course.items

export default CourseSlice.reducer
